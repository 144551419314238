<template>
  <sign-page
    ref="wagePage"
    title-text="职工工资"
    :request="request"
    :column-list="columnList"
    :title-menus="[{key: 'input', label: '导入'}, {key: 'outPut', label: '导出'}, {key: 'outPutP', label: '导出模版'}, {key: 'clear', label: '清空'}]"
    :searchParms="[{key: 'month', label: '月份', default: lastMenth, type: 'select', selectDatas: months}]"
    :table-actions="[]"
    table-size="large"
    :export-file-name="exportFileName"
    :dealSearchParm="dealSearchParm"
    :initSearchData="{month: lastMenth}"
    @clickTitleMenu="clickTitleMenu"
    :need-data-file="true">
    <input-file-update
      :fileParm="fileParm"
      :open-dialog.sync="openDialogFile"
      @inputFileUpdateOver="inputFileUpdateOver"
      :deal-file="dealFile"
      @handleClose="openDialogFile = false">
    </input-file-update>
  </sign-page>
</template>

<script>
import {
  wageRequest as request
} from '../../api'

import {
  getLastMonth
} from '@/syslib/tools'

import {
  fileHepler
} from '@/fmlib'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

import InputFileUpdate from '../../components/base/InputFileUpdate'

export default {
  components: {
    InputFileUpdate
  },
  computed: {
    months () {
      let i = 12
      let data = []
      while (i > -1) {
        data[i] = getLastMonth(data[i + 1] ? data[i + 1] + '-01' : null)
        i -= 1
      }
      return data.map((v) => {
        return {key: v, label: v}
      })
    },
    columnList () {
      let data = [
        { field: 'month', title: '月份', width: 80, fixed: 'left', sort: true, dataType: Date },
        {field: 'workerName', title: '姓名', width: 110, fixed: 'left', sort: true}]
      if (this.datas && this.datas.length > 0) {
        let dataMap = this.datas[0].sy
        dataMap = dataMap ? JSON.parse(dataMap) : {}
        Object.keys(dataMap).forEach(key => data.push({
          field: '__' + key,
          title: key,
          sort: true
        }))
      }
      return data
    }
  },
  created () {
    if (this.$authFunsProxy.input) {
      loadSelectWorkerList(this)
    }
  },
  methods: {
    async loadData (parm) {
      let data = await loadData(request, this, parm)
      data.forEach((v) => {
        let dataMap = v.sy
        dataMap = dataMap ? JSON.parse(dataMap) : {}
        Object.keys(dataMap).forEach(key => v['__' + key] = dataMap[key])
      })
      this.datas = data
      return data
    },
    async dealFile (file, vm) {
      vm.loading = true
      let data = await fileHepler.readExcelFileData({file, allsheets: true})
      let data1 = []
      Object.keys(data).forEach(key => data1 = data1.concat(data[key]))
      await request.clear({month: this.month})
      await this.dealData(data1)
      vm.$emit('inputFileUpdateOver')
      vm.$notice.success({
        title: '系统提示',
        desc: '导入更新完成'
      })
      vm.loading = false
      vm.openDialogFile = false
    },
    async dealData (datas) {
      let fields = datas[0].slice(1)
      let i = 1
      while (i < datas.length) {
        let worker = getSelectWorkerList(this).find(v => v.data.code === datas[i][0])
        let dataItem = {}
        fields.forEach((key, index) => {
          dataItem[key] = datas[i][index + 1]
        })
        await request.add({
          month: this.month,
          workerId: worker && worker.data.id ? worker.data.id : null,
          sy: JSON.stringify(dataItem)
        })
        i += 1
      }
    },
    exportFileName () {
      return this.month + '职工工资'
    },
    dealSearchParm (parm) {
      this.month = parm.month
      if (!this.month) {
        this.$notify({
          title: '系统提示',
          message: '月份不能为空',
          type: 'info'
        })
        throw new Error('月份不能为空')
      }
      return parm
    },
    async clickTitleMenu (key) {
      if (key === 'clear') {
        if (!this.month) {
          this.$notify({
            title: '系统提示',
            message: '月份不能为空',
            type: 'info'
          })
          return 
        }
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定清空职工工资数据吗?'})
        if (result) {
          await request.clear({month: this.month})
          this.$refs.wagePage.loadData({month: this.month})
        }
      } else if (key === 'outPut') {
        if (!this.month) {
          this.$notify({
            title: '系统提示',
            message: '月份不能为空',
            type: 'info'
          })
          return 
        }
        let datas = await this.loadData({
          month: this.month
        })
        fileHepler.outPutTableData(datas, this.columnList, this.month + '职工工资.xlsx', this.month)
      } else if (key === 'outPutP') {
        fileHepler.outPutTableData([], [{field: 'code', title: '职工工号'}].concat(this.columnList.filter(v => v.field !== 'month' && v.field !== 'workerName')), '职工工资模版.xlsx', '模版')
      } else {
        if (!this.month) {
          this.$notify({
            title: '系统提示',
            message: '月份不能为空',
            type: 'info'
          })
          return 
        }
        this.fileParm.sparm.month = this.month
        this.openDialogFile = true
      }
    },
    inputFileUpdateOver () {
      this.$refs.wagePage.loadData({month: this.month})
    }
  },
  data () {
    return {
      openDialogFile: false,
      datas: [],
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      lastMenth: getLastMonth(),
      fileParm: {
        sparm: {
          dataType: 'SourceWage',
          month: null
        },
        title: '导入工资',
        label: '工资文件'
      }
    }
  }
}
</script>
